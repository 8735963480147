.text-ul li {
    position: relative;
    list-style: none;
    opacity: 1;
    font-weight: bold;
}
.text-ul li:before {
    content: "";
    position: absolute;
    left: -17px;
    top: 12px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #F75757;
}
 
.absolute{
    position: absolute;
}
.dots1{
    z-index: -1;
    top: 50%;
    left: -1%;
    transform: translateY(-50%);
    width: 300px;
}
.dots2{
    top: -1%;
    left: 50%;
    transform: translateX(-50%);
    width: 140px;
}
.dots3{
    top: 50%;
    right: -1%;
    transform: translateY(-22%);
    width: 100px;
}
.swiper{
    padding:30px 0;
}
.nft-card {
    transition: all 0.5s
}
.nft-card:hover {
    transform: scale(1.02);
}
@media screen and (max-width: 1024px){
.react-jinke-music-player-mobile {
    background-color: rgba(0,0,0,.75);
    bottom: 0;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    left: 0;
    overflow: hidden;
    padding: 20px;
    position: fixed;
    right: 0;
    top: 90px;
    width: 100%;
    z-index: 999;
  }
}
@media screen and (max-width: 1024px){
     .audio-lists-panel-mobile {
    background-color: rgba(0,0,0,.75);
    border-radius: 0;
    bottom: 0;
    height: auto!important;
    left: 0;
    right: 0;
    top: 100px;
    transform-origin: bottom center;
    width: 100%!important;
  }
}